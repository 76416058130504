.Footer {
    padding: 25px;
    background-color: rgb(31,31,31);
    color: white;
}

.Footer1 {
    
    border-bottom: 2px solid #ccc;
    
}